import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { getBudget } from '@bbx/search-journey/common/api/mortgagesApiClient'
import { MortgageFinancingExampleModal } from '@bbx/search-journey/common/components/MortgageFinancingExampleModal/MortgageFinancingExampleModal'
import {
    BudgetCalculatorFormValues,
    editBudgetFieldRequiredness,
    fieldToLabelMap,
    validateBudgetFormWithErrors,
} from '@bbx/search-journey/sub-domains/search/components/common/common/BudgetCalculator/budgetCalculatorForms'
import { Box } from '@wh-components/core/Box/Box'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { Text } from '@wh-components/core/Text/Text'
import { theme } from '@wh-components/core/theme'
import { DisabledOnSSRButton } from '@wh/common/chapter/components/DisabledOnSSRButton'
import { FormikInput, FormikRadioGroup, FormikToggle } from '@wh/common/chapter/components/Formik/FormikInputs'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { getWohnkrediteDomain } from '@wh/common/chapter/lib/config/runtimeConfig'
import { formatNumber, formatPrice } from '@wh/common/chapter/lib/formatter'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { isNumberString } from '@wh/common/chapter/lib/validation'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { FormikHelpers, useFormik } from 'formik'
import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { css } from 'styled-components'

interface BudgetCalculatorProps {
    taggingData?: TaggingData
    disabledHeader?: boolean
    disableSiteMutationStyles?: boolean
    disableFooter?: boolean
    footerSubtext?: string
    setToPriceToBudget?: (price: string) => void | undefined
}

export const BudgetCalculator: FunctionComponent<BudgetCalculatorProps> = ({
    taggingData,
    disabledHeader = false,
    disableSiteMutationStyles = false,
    disableFooter = false,
    footerSubtext,
    setToPriceToBudget,
}) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const budgetBoxBackgroundColor = advertisingState.pageModifications.backgroundColors?.['estate-search-box']
    const budgetBoxBorderColor = advertisingState.pageModifications.borders?.['estate-search-box']
    const searchBoxForegroundTextColor = advertisingState.pageModifications.foregroundColors?.['estate-search-box-text']
    const searchBoxForegroundLinkColor = advertisingState.pageModifications.foregroundColors?.['estate-search-box-links']
    const searchBoxSearchButtonOutlineActivated = advertisingState.pageModifications.borders?.['estate-search-box-search-button'] ?? false

    const [exampleModalOpen, setExampleModalOpen] = useState(false)
    const [interacted, setInteracted] = useState<boolean>(false)

    const [uiState, setUiState] = useState<'loading' | 'loaded' | 'error'>('loading')
    const [budget, setBudget] = useState<{ maxPrice: number | undefined; maxRate: number; interestRate: number | undefined } | undefined>(
        undefined,
    )

    const onSendForm = useCallback((formValues: BudgetCalculatorFormValues, formikHelpers: FormikHelpers<BudgetCalculatorFormValues>) => {
        formikHelpers.setSubmitting(false)

        const { netIncome, ownFunds, withAgentCosts, variableOrFix } = formValues

        window.open(getLinkToMortgagesWebpage(netIncome, ownFunds, withAgentCosts, variableOrFix), '_self')
    }, [])

    const formProps = useFormik({
        initialValues: { netIncome: '3000', ownFunds: '90000', withAgentCosts: true, variableOrFix: 'fixed' },
        validate: validateBudgetFormWithErrors,
        onSubmit: onSendForm,
    })

    const formSpecificFieldProps = {
        formProps,
        fieldLabelMap: fieldToLabelMap,
        fieldRequiredness: editBudgetFieldRequiredness,
        showRequiredLabel: false,
        validateOnChange: true,
    }

    useEffect(() => {
        const fetchMortgageBudget = async () => {
            try {
                const { netIncome, ownFunds, withAgentCosts, variableOrFix } = formProps.values

                if (!isNumberString(netIncome) || !isNumberString(ownFunds)) {
                    setUiState('error')
                    return
                }

                const budgetResponse = await getBudget(Number(netIncome), Number(ownFunds), withAgentCosts, variableOrFix)

                setBudget(
                    budgetResponse
                        ? {
                              maxPrice: budgetResponse.maxRealEstatePrice,
                              maxRate: budgetResponse.maxMonthlyRate,
                              interestRate: budgetResponse?.interestRate * 100,
                          }
                        : {
                              maxPrice: undefined,
                              maxRate: 0,
                              interestRate: undefined,
                          },
                )
                setUiState('loaded')
            } catch (_error) {
                setUiState('error')
            }
        }
        fetchMortgageBudget()
    }, [formProps.values])

    useEffect(() => {
        if (setToPriceToBudget) {
            setToPriceToBudget(String(Math.round(budget?.maxPrice ? budget?.maxPrice : 0)))
        }
    }, [budget?.maxPrice, setToPriceToBudget])

    return (
        <Box
            backgroundColor={
                disableSiteMutationStyles
                    ? { phone: 'semantic.surface', tablet: 'semantic.surface' }
                    : (budgetBoxBackgroundColor ?? { phone: 'palette.polarbear', tablet: 'semantic.surface' })
            }
            padding={{ tablet: disableSiteMutationStyles ? undefined : 'm' }}
            paddingTop={{ phone: disableSiteMutationStyles ? undefined : 'm' }}
            paddingBottom={disableSiteMutationStyles ? undefined : 's'}
            border={{
                tablet:
                    budgetBoxBorderColor && !disableSiteMutationStyles
                        ? `1px solid ${budgetBoxBorderColor}`
                        : disableSiteMutationStyles
                          ? undefined
                          : 'owl',
            }}
            borderRadius={{ tablet: disableSiteMutationStyles ? undefined : 'm' }}
            testId="budgetCalculatorBoxWrapper"
            color={searchBoxForegroundTextColor ?? undefined}
        >
            <Box
                display="flex"
                flexDirection="column"
                padding={{ phone: disableSiteMutationStyles ? '0' : 'm', tablet: '0', desktop: '0' }}
                gap="s"
            >
                {!disabledHeader && (
                    <Box marginBottom="s">
                        <AdBoxHeading text="Kaufbudgetrechner" />
                        <Text fontSize="s">Das kannst du dir mit Hilfe einer Immobilienfinanzierung leisten.</Text>
                    </Box>
                )}

                <form
                    data-testid="budgetCalculatorForm"
                    onSubmit={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        formSpecificFieldProps.formProps.handleSubmit()
                        callActionEvent('real_estate_vertical_home_mortgage_budget_calculator_submit', taggingData)
                    }}
                    onChange={() => {
                        if (!interacted && !disableFooter) {
                            callActionEvent('real_estate_vertical_home_mortgage_budget_calculator_interaction', taggingData)
                            setInteracted(true)
                        }
                    }}
                >
                    <Box display="flex" flexDirection="column" gap={{ phone: 'm', tablet: 's' }}>
                        <Box
                            display="flex"
                            flexDirection={{ phone: 'column', tablet: 'row-reverse' }}
                            justifyContent="space-between"
                            gap="s"
                        >
                            <Box display="flex" flexDirection="column" alignItems={{ phone: 'flex-start', tablet: 'flex-end' }} gap="xs">
                                {uiState !== 'loading' ? (
                                    <Fragment>
                                        <Text fontWeight="bold">Maximal möglicher Kaufpreis</Text>
                                        <Box
                                            display="flex"
                                            flexDirection={{ phone: 'row', tablet: 'column' }}
                                            alignItems={{
                                                phone: 'baseline',
                                                tablet: 'flex-start',
                                                desktop: 'flex-end',
                                            }}
                                            gap={{
                                                phone: 's',
                                                tablet: 'xs',
                                            }}
                                        >
                                            <Text
                                                fontSize="xxxl"
                                                fontWeight="bold"
                                                lineHeight={1}
                                                color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                                            >
                                                {budget?.maxPrice ? formatPrice(Math.round(budget?.maxPrice)) : 'kein Preis'}
                                            </Text>
                                        </Box>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Box width={{ phone: '50%', tablet: '70%' }} height={19}>
                                            <SkeletonLine />
                                        </Box>
                                        <Box width={{ phone: '70%', tablet: '50%' }} height={26}>
                                            <SkeletonLine />
                                        </Box>
                                        <Box display={{ phone: 'none', tablet: 'block' }} width="55%" height={19}>
                                            <SkeletonLine />
                                        </Box>
                                    </Fragment>
                                )}

                                <Box
                                    display="flex"
                                    width="100%"
                                    flexDirection={{ phone: 'row', tablet: 'column' }}
                                    alignItems={{ phone: 'flex-start', tablet: 'flex-end' }}
                                >
                                    <Text
                                        width="110px"
                                        alignSelf={{ phone: 'center', tablet: 'flex-end' }}
                                        fontSize="s"
                                        color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                                    >
                                        Zinssatz
                                        {budget?.interestRate && (
                                            <Fragment>
                                                <Text fontWeight="bold"> {formatNumber(budget?.interestRate)}%</Text> p.a.
                                            </Fragment>
                                        )}
                                    </Text>

                                    <FormikRadioGroup
                                        marginLeft="m"
                                        marginVertical="s"
                                        field="variableOrFix"
                                        inline={true}
                                        radios={[
                                            {
                                                value: 'variable',
                                                label: 'Variabel',
                                            },
                                            {
                                                value: 'fixed',
                                                label: 'Fix',
                                            },
                                        ]}
                                        css={css`
                                            label {
                                                color: ${searchBoxForegroundTextColor};
                                            }
                                        `}
                                        {...formSpecificFieldProps}
                                    >
                                        {(radioNodes) => (
                                            <Box display="flex" flexWrap="wrap" gap="m">
                                                {radioNodes}
                                            </Box>
                                        )}
                                    </FormikRadioGroup>
                                </Box>
                                {!disableFooter && (
                                    <ButtonWithLinkStyle
                                        onClick={() => {
                                            setExampleModalOpen(true)
                                        }}
                                        color={searchBoxForegroundLinkColor ?? undefined}
                                    >
                                        Finanzierungsbeispiel anzeigen
                                    </ButtonWithLinkStyle>
                                )}
                            </Box>
                            <Box display="flex" flexDirection="column" gap="m" flex={{ tablet: '0 0 270px' }}>
                                <Box width="100%">
                                    <FormikInput
                                        field="netIncome"
                                        size="medium"
                                        maxLength={5}
                                        style={{ color: theme.colors.palette.primary.main, fontWeight: 'bold' }}
                                        placeholder="Einkommen"
                                        inputMode="numeric"
                                        onInput={(e) => allowJustDigits(e)}
                                        formatter={formatNumber}
                                        prepend="€"
                                        css={css`
                                            label * {
                                                color: ${searchBoxForegroundTextColor};
                                            }
                                        `}
                                        {...formSpecificFieldProps}
                                    />
                                </Box>
                                <Box width="100%">
                                    <FormikInput
                                        field="ownFunds"
                                        size="medium"
                                        maxLength={7}
                                        style={{ color: theme.colors.palette.primary.main, fontWeight: 'bold' }}
                                        placeholder="Eigenmittel"
                                        inputMode="numeric"
                                        onInput={(e) => allowJustDigits(e)}
                                        onKeyUp={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            if (e.key === 'Enter') {
                                                e.currentTarget.blur()
                                            }
                                        }}
                                        formatter={formatNumber}
                                        prepend="€"
                                        css={css`
                                            label * {
                                                color: ${searchBoxForegroundTextColor};
                                            }
                                        `}
                                        {...formSpecificFieldProps}
                                    />
                                </Box>
                                <Box width="100%">
                                    <FormikToggle field="withAgentCosts" {...formSpecificFieldProps} />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection={{ phone: 'column', tablet: 'row' }}
                            justifyContent={{ tablet: 'space-between' }}
                            gap="m"
                        >
                            <Text fontSize="s">
                                Maximale&nbsp;
                                <Text fontWeight="bold">
                                    monatliche Rate:&nbsp;
                                    <Text color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}>
                                        {formatPrice(Math.round(budget?.maxRate ? budget?.maxRate : 0))}
                                    </Text>
                                </Text>
                            </Text>
                            {!disableFooter && (
                                <DisabledOnSSRButton
                                    id="submitButton"
                                    disabled={formSpecificFieldProps.formProps.isSubmitting}
                                    width={{ phone: '100%', tablet: '250px' }}
                                    alignSelf="right"
                                    testId="budgetCalculatorSubmit"
                                    type="submit"
                                    size="medium"
                                    css={css`
                                        box-shadow: ${searchBoxSearchButtonOutlineActivated &&
                                        `0 0 0 1px ${theme.colors.semantic.surface.DEFAULT}`};
                                    `}
                                >
                                    Jetzt informieren
                                </DisabledOnSSRButton>
                            )}
                        </Box>
                        {footerSubtext && (
                            <Text fontSize="s" color="palette.grey" textAlign={{ tablet: 'right' }}>
                                {footerSubtext}
                            </Text>
                        )}
                    </Box>
                </form>
                <MortgageFinancingExampleModal isOpen={exampleModalOpen} onRequestClose={() => setExampleModalOpen(false)} />
            </Box>
        </Box>
    )
}

const getLinkToMortgagesWebpage = (
    netIncome: string,
    ownFunds: string,
    withAgentCosts: undefined | boolean,
    variableOrFixed: 'variable' | 'fixed',
) => {
    return `${getWohnkrediteDomain()}/?income=${netIncome}&equity=${ownFunds}&interestRate=${variableOrFixed}&realEstateAgent=${withAgentCosts}&utm_source=wohnkredite_ad&utm_campaign=immostart_tab`
}

const allowJustDigits = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement
    input.value = input.value.replace(/[^0-9]/g, '')
}

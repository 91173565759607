import { Navigator, NavigatorId, PossibleNavigatorValue, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import {
    ModalNavigatorModal,
    NavigatorModalProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/ModalNavigatorModal'
import { useModalNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useModalNavigator'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import {
    SelectedValues,
    SelectedValuesProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/SelectedValues'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { CheckboxSize } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { Text } from '@wh-components/core/Text/Text'
import { Tooltip } from '@wh-components/core/Tooltip/Tooltip'
import PlusIcon from '@wh-components/icons/Plus'
import { WidthProps } from '@wh-components/system/layout'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { isTruncated, sortAlphabetically } from '@wh/common/chapter/lib/commonHelpers'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react'
import { css } from 'styled-components'

export interface ModalNavigatorProps {
    id?: NavigatorId
    showSelectedValues?: boolean
    disabled?: boolean
    selectedValueInColumns?: boolean
    testIdFragmentOverride?: string
    taggingEvent?: TaggingActionEvent
    getSelectedValuesText?: (navigator: Navigator | RangeNavigator | undefined) => string
    showHelpText?: boolean
    checkboxSize?: CheckboxSize | undefined
    checkedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    uncheckedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
}

export const ModalNavigator: FunctionComponent<
    Omit<NavigatorProps, 'navigator'> & { navigator: Navigator | undefined } & NavigatorModalProps &
        ModalNavigatorProps &
        SelectedValuesProps &
        Partial<Pick<WidthProps, 'width' | 'maxWidth'>>
> = ({
    id,
    navigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    label = navigator?.label,
    modalHeader,
    showSelectedValues = true,
    sortSelectedValues = true,
    width = '100%',
    disabled = false,
    maxWidth,
    selectedValueInColumns = false,
    testIdFragmentOverride,
    taggingEvent,
    taggingData,
    getSelectedValuesText = getSelectedValuesTextDefault,
    showHelpText = false,
    checkboxSize,
    checkedContent,
    uncheckedContent,
}) => {
    const { checkboxState, handleChange, onSubmitMultiSelect, modalOpen, openModal, closeModal, setCheckboxState } = useModalNavigator(
        navigator,
        onSearch,
        taggingData,
    )
    const navigatorId = navigator?.id ?? id
    const navigatorLabelId = `navigator-title-${navigatorId}`
    const combinedAriaLabelledBy = [ariaLabelledBy, navigatorLabelId].filter(Boolean).join(' ')

    const hasSelectedValues = (navigator?.selectedValues.length ?? 0) > 0
    const buttonText = (!showSelectedValues && hasSelectedValues ? getSelectedValuesText(navigator) : label) ?? ''

    return (
        <Fragment>
            {navigator && (
                <ModalNavigatorModal
                    checkboxState={checkboxState}
                    handleChange={handleChange}
                    onSubmitMultiSelect={onSubmitMultiSelect}
                    modalOpen={modalOpen}
                    openModal={openModal}
                    closeModal={closeModal}
                    modalHeader={modalHeader}
                    navigator={navigator}
                    onSearch={onSearch}
                    setCheckboxState={setCheckboxState}
                    checkboxSize={checkboxSize}
                    checkedContent={checkedContent}
                    uncheckedContent={uncheckedContent}
                />
            )}
            {showHelpText && (
                <Text display="block" marginBottom="xs" fontWeight="semibold">
                    {buttonText}
                </Text>
            )}
            <Box borderTopRadius="m">
                <ModalNavigatorButton
                    testIdFragment={(testIdFragmentOverride || navigatorId) ?? ''}
                    width={width}
                    maxWidth={maxWidth}
                    openModal={openModal}
                    hasSelectedValues={hasSelectedValues}
                    disabled={disabled}
                    buttonText={`${buttonText}${showHelpText ? ' wählen...' : ''}`}
                    taggingEvent={taggingEvent}
                    taggingData={taggingData}
                />
                {showSelectedValues && hasSelectedValues && navigator && (
                    <Box backgroundColor={showSelectedValues && hasSelectedValues && 'palette.polarbear'}>
                        <SelectedValues
                            showInColumns={selectedValueInColumns}
                            sortSelectedValues={sortSelectedValues ? (a, b) => sortAlphabetically(a.label, b.label) : undefined}
                            navigator={navigator}
                            onSearch={onSearch}
                            aria-labelledby={combinedAriaLabelledBy}
                            taggingData={taggingData}
                        />
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}

interface ModalNavigatorButtonProps {
    testIdFragment: string
    openModal: () => void
    disabled: boolean
    buttonText: string
    hasSelectedValues: boolean
    taggingEvent?: TaggingActionEvent
    taggingData: TaggingData
}

export const ModalNavigatorButton: React.FunctionComponent<ModalNavigatorButtonProps & Partial<Pick<WidthProps, 'width' | 'maxWidth'>>> = ({
    testIdFragment,
    width,
    maxWidth,
    openModal,
    hasSelectedValues,
    disabled,
    buttonText,
    taggingEvent,
    taggingData,
}) => {
    const buttonLabelRef = useRef<HTMLSpanElement>(null)
    const [displayLabel, setDisplayLabel] = useState(false)

    const screenSize = useScreenSize()

    useEffect(() => {
        setDisplayLabel(isTruncated(buttonLabelRef.current))
    }, [buttonText])

    return (
        <Box testId={`navigator-${testIdFragment}-button`}>
            <Tooltip content={buttonText} maxWidth="350px" disabled={screenSize === 'phone' || !displayLabel || !hasSelectedValues}>
                <Button
                    testId={`navigator-${testIdFragment}-open-modal-button`}
                    variant="outline"
                    color="complimentary"
                    width={width}
                    maxWidth={maxWidth}
                    size={{ phone: 'large', tablet: 'medium' }}
                    onClick={() => {
                        openModal()
                        if (taggingEvent) {
                            callActionEvent(taggingEvent, taggingData)
                        }
                    }}
                    disabled={disabled}
                    css={css`
                        &:disabled {
                            background-color: ${(p) => p.theme.colors.palette.babyseal};
                        }
                    `}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <NavigatorLabel
                            truncate={true}
                            ref={buttonLabelRef}
                            color={
                                hasSelectedValues
                                    ? 'palette.primary.main'
                                    : disabled
                                      ? 'semantic.foreground.inactive'
                                      : 'palette.verydarkgrey'
                            }
                            fontWeight={hasSelectedValues ? 'bold' : 'regular'}
                            testId={`navigator-${testIdFragment}-button-label`}
                            marginRight="s"
                        >
                            {buttonText}
                        </NavigatorLabel>
                        <PlusIcon size="medium" color={disabled ? 'semantic.foreground.inactive' : 'palette.elephant'} />
                    </Box>
                </Button>
            </Tooltip>
        </Box>
    )
}

export const getSelectedValuesTextDefault = (navigator: Navigator | RangeNavigator | undefined) => {
    return navigator?.selectedValues
        .map((value) => value.label)
        .sort((a, b) => sortAlphabetically(a, b))
        .join(', ')
}

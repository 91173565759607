import React, { FunctionComponent } from 'react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import styled, { css } from 'styled-components'
import NewIcon from '@wh-components/icons/CatNewconstruction'
import ArrowRight from '@wh-components/icons/ArrowRight'
import PersonalizationBadgeIcon from '@wh-components/icons/PersonalizationBadge'
import { useFeatureToggle } from '@wh/common/chapter/components/FeatureToggleProvider/FeatureToggleProvider'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useRouter } from 'next/router'

export const PersonalizationOnboardingBox = () => {
    const showPersonalization = useFeatureToggle('enableSizePersonalization')
    if (!showPersonalization) {
        return null
    }

    const TITLE = 'Meine Größen'
    const TEXT = 'Jetzt neu – Speichere deine Größen im Personalisierungsbereich ab, um immer passende Kleidung ausgespielt zu bekommen.'
    return <OnboardingBox title={TITLE} text={TEXT} />
}

interface OnboardingBox extends BoxProps {
    title: string
    text: string
}
export const OnboardingBox: FunctionComponent<OnboardingBox> = ({ title, text }) => {
    const router = useRouter()
    const personalizationUrl = `/iad/myprofile/personalization?onboardingUrl=${encodeURIComponent(router.asPath)}`
    return (
        <Link
            type="anchor"
            href={personalizationUrl}
            color="palette.verydarkgrey"
            testId="onboardingBox_click_personalization"
            onClick={() => {
                return callActionEvent('search_result_list_personalization_filter_onboarding_box_click', undefined)
            }}
        >
            <Box
                className="perso-link"
                border="1px solid"
                borderColor="alert.info.background"
                paddingTop="s"
                paddingBottom="m"
                paddingHorizontal="m"
                backgroundColor="alert.info.background"
            >
                <Box display="flex" flexDirection="row" marginBottom="0" alignItems="center">
                    <PersonalizationBadgeIcon size="3.5em" marginRight="xs" />
                    <Box marginVertical="xs" display="flex" flexDirection="column" justifyContent="space-between">
                        <Box fontWeight="bold" fontSize="m" display="flex" alignItems="center" color="palette.main">
                            {title}
                            <NewIcon
                                size="medium"
                                marginLeft="xs"
                                css={css`
                                    fill: ${(p) => p.theme.colors.palette.accent.secondary.main};
                                `}
                            />
                        </Box>
                        <Text as="span" fontSize="m" marginRight="s">
                            {text}
                        </Text>
                    </Box>
                    <ArrowRight marginLeft="auto" size="s" color="palette.raccoon" />
                </Box>
            </Box>
        </Link>
    )
}
const Link = styled(ClientRoutingAnchorLink)`
    &:hover {
        text-decoration: none;
    }
`
